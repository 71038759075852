import React from 'react'
import './main.scss';
import './Content2.scss';
import { useState } from 'react';
import { send } from 'emailjs-com';
import Mail from './pages/mail';

import 'font-awesome/css/font-awesome.min.css';



function Content2() { 
    const [toSend, setToSend] = useState({
        First_name: '',
        Last_name: '',
        message: '',
        reply_to: '',
      });
   
      const onSubmit = (e) => {
        e.preventDefault();
        send(
          'service_wgz99ns',
            'template_i6v44a4',
            toSend,
           
            'user_eD8LRdTbof9ni0Y8wIodK'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
      };
    
    
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };



return (
        <div classname='all'>
        <div className='paste'>
        <div className="Content2">
        <b className="venus">
        
            <head>
                <title>Create Next App</title>
                <link rel="icon" href="/favicom.ico"/>
            </head>

           <h1>Contact Us</h1> 
           

           <div id="contact-container">
             <div className="contact-info">
                 <h4>
                     Contact Information
                 </h4>
                 <p>Fill up the form and then click submit</p>
             <div className="icon-text">
             <i class="fa fa-phone" aria-hidden="true"></i>
                           <span>+254-794-589-167</span>
                      
                       </div>
                       <div className="icon-text">
             <i class="fa fa-phone" aria-hidden="true"></i>
                        
                           <span>+254-713-008-842-control room 24/7</span>
                
                       </div>
                       <div className="icon-text">
             <i class="fa fa-phone" aria-hidden="true"></i>
                     
                           <span>+254-708-622-903</span>
                       </div>
                       
                       
                       <div className="icon-text">
                       <i class="fa fa-envelope-o" aria-hidden="true"></i>
                           <span>customercare@gpridetracking.com</span>
                       </div>
                       <div className="icon-text">
                       <i class="fa fa-map-marker" aria-hidden="true"></i>
                           <span> Eastern bypass, Off Thika Road, Exit 11, Plateau Business Park, Second floor,<b> Next to Small Villa Hotel</b></span>
                       </div>
                       <div className="social-media">
                           <a href="#" className="icon-circle">
                           <i class="fa fa-facebook-official" aria-hidden="true"></i>

                           </a>
                           <a href="#" className="icon-circle">
                           <i class="fa fa-twitter" aria-hidden="true"></i>


                           </a>
                           <a href="#" className="icon-circle">
                           <i class="fa fa-instagram" aria-hidden="true"></i>
                           </a>
                           
                       </div>
                   </div>  
                   <div classname="stem">
                     <h1 style={{paddingLeft:'100px'}}>We Offer</h1>
                     <ol style={{paddingLeft:'100px'}}>
                       <li style= {{color: 'white' }}  >Car Tracking and Fleet Management</li>
                       <li style= {{color: 'white' }} >Software Development and Programming</li>
                       <li style= {{color: 'white' }} >CCTV Installation</li>
                       <li style= {{color: 'white' }} >Car Alarm System Installation</li>
                       <li style= {{color: 'white' }} >Car Hire</li>
                       <li style= {{color: 'white' }} >Key Programming</li>
                     </ol>
                     <Mail/>
                   </div>
                  
                  
           </div>
           </b>
        </div>
        </div>
        </div>
        
    )

}

export default Content2;
