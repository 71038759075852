/*import React from 'react'
import ReactDOM from 'react-dom';
 import MapSection from '../Map'

 
export default function next() {
    const read = {
        address: "BP 3677 , Boulevard de l'Independance Num 4 Rohero I , Bujumbura, Burundi",
        lat: -3.384135,
        lng:  29.3722712
      }
    return (
        <>
         <MapSection location={read} zoomLevel={17} /> { MapSection}
        </>
    )
}
*/
import React from 'react'
import '../../App.css'

 import MapSection from '../Map2'
 


const location = {
    address: "BP 3677 , Boulevard de l'Independance Num 4 Rohero I , Bujumbura, Burundi",
    lat: -3.384135,
    lng:  29.3722712
    
  }
  
function Home(){
    return (
        <>
        
        <MapSection location={location} zoomLevel={17} /> { MapSection}
     
   
        </>
    )
}
export default Home;
