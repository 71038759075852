import React from 'react'
import '../../App.css';

import Footer from '../Footer';
import One2 from "../one2"
function one() {
    return (
        
        <>
        <One2 />
        <Footer />
        </>
  
    )

}

export default one;
