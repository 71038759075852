import React from 'react'
import './main.scss';
import './Content4.scss';
import 'font-awesome/css/font-awesome.min.css';
import img1 from '../img-4.jpg';


function Content4() {
    return (
        <div className="Content4" style={{ backgroundImage:img1  }}>
    
        
            <head>
                <title>Create Next App</title>
                <link rel="icon" href="/favicom.ico"/>
            </head>

           <p>G pride tracesoft group offers state of the<br></br>
           art softtware systems for vehicle,track and<br></br> fleet management.<br></br>
           Access it through the following methods:</p> 
           <div className="social-media">
                           <a href="https://traccar.gpridetracking.com" className="icon-circle">
                           <i class="fa fa-globe" aria-hidden="true"></i>


                           </a>
                           <a href="https://apps.apple.com/us/app/traccar-manager/id1113966562" className="icon-circle">
                           <i class="fa fa-apple" aria-hidden="true"></i>



                           </a>
                           <a href="https://play.google.com/store/apps/details?id=org.traccar.manager" className="icon-circle">
                           <i class="fa fa-android" aria-hidden="true"></i>

                           </a>
                           
                       </div>
           

           <div id="contact-container">
             
           </div>
           
        </div>
        
    )
} 

export default Content4;
