import React from 'react'
import '../../App.css'
import HeroSection from "../HeroSection"
import Cards from '../Cards';
import Footer from '../Footer';
import Content5 from "../Content5"

 import MapSection from '../Map'
 import Next from './Next'


const location = {
    address: 'G-pride Tracesoft Group Ltd. Roundabout, Plateau Business Park Eastern Bypass, Thika Rd, Nairobi, Kenya.',
    lat: -1.1634506,
    lng: 36.9544301,
  }

function Home(){
    return (
        <>
        <HeroSection/>
        <Cards/>
        <Content5/>
        <MapSection location={location} zoomLevel={17} /> { MapSection}
      
        <Next/>
        <Footer/>
        
        </>
    )
}
export default Home;
