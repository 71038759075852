import React from 'react'
import './main.scss';
import './Content5.css';
import 'font-awesome/css/font-awesome.min.css';



function Content5() {
    return (
        <div className="Content5">
    
        
            <head>
                <title>Create Next App</title>
                <link rel="icon" href="/favicom.ico"/>
            </head>

           <h1 style={{paddingTop: '18px' }} ><u> OUR PLANS</u></h1>
           <div>
           <h1 style= {{color: 'black' }} style={{fontSize: '18px' }} style={{paddingTop: '15px' }}><u>PREMIUM</u></h1>
           <ul style= {{color: 'white' }}  style={{fontSize: '18px' }} style={{paddingTop: '15px' }}>
               <li style= {{color: 'white' }} >Devices installed: 1 per vehicle</li>
               <li style= {{color: 'white' }}>Warranty: lifetime of Service</li>
               <li style= {{color: 'white' }}>Access: Account provision with username and password</li>
               <li style= {{color: 'white' }}>Pricing: Request quote for full details</li>
           </ul>
           
           </div>
           <div>
               <h1 style={{paddingTop: '18px' }}><u>ENTERPRISE</u></h1>
               <div classname='Enterprice'>
               <ul style= {{color: 'white' }} style={{fontSize: '18px' }} style={{paddingTop: '15px' }}>
               <li style= {{color: 'white' }}>Devices installed: 2 per vehicle</li>
               <li style= {{color: 'white' }}>Warranty: lifetime of Service</li>
               <li style= {{color: 'white' }}>Access: Account provision with username and password</li>
               <li style= {{color: 'white' }}>Pricing: Request quote for full details</li>
           </ul>
           </div>
           </div>
        </div>
        
    )
} 

export default Content5;
