import React from 'react'
import './Spear.css';
const Spear = () => {
    return (
        <div className="flat">
            <h1 style= {{color: '#153f7c' }}>OUR PARTNERS</h1>
        </div>
    )
}

export default Spear
