import React from 'react';
import '../../App.css';
import Footer from '../Footer';


import Content3 from '../Content3';


export default function Products() {
    return (
      <>
      
      <Content3/>
      <Footer></Footer>
    
      </>
  )
  
}