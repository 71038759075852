import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ImageSlider from "../ImageSlider"
import {SliderData} from '../SliderData'
import Spear from '../Spear';

import Content2 from '../Content2';


export default function SignUp() {
    return (
      <>
      
      <Content2/>
     
      <Spear/>
      <ImageSlider slides={SliderData}/>
      <Footer></Footer>
    
      </>
  )
  
}