import img1 from '../doshi.jpg';
import img2 from '../ndalex.jpg';
import img3 from '../ngong catholic.PNG';
import img4 from '../carmax.jpg';
import img5 from '../gigi.png';
import img6 from '../ngong catholic.PNG'; 
import img7 from '../jip.png';
import img8 from '../shoppers.PNG';
import img9 from '../micronesia.PNG';
import img10 from '../vesky.PNG';

import img12 from '../mex.png';
import img13 from '../mobisol.PNG';
import img14 from '../impact motors.png';

import img16 from '../cng copy.png';


export const SliderData=[

    {
        
      image: img1
      
      
    },
    {
        image:img2
   
    },
    {
        image:img3
        

    },
    {
        image:
        img4
    },
    {
        image:
        img5
    },
    {
        image:
        img6
    },
    {
        image:
        img7
    },
    {
        image:
        img8
    },
    {
        image:
        img9
    },
    {
        image:
        img10
    },
    
    {
        image:
        img12
    },
    {
        image:
        img13
    },
    {
        image:
        img14
    },
    
    {
        image:
        img16
    }



    

]