import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import 'font-awesome/css/font-awesome.min.css';
import logo from '../logotagline.png'



function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
        <img style={{ borderRadius: 600/ 2}}  src={logo} width="100"  borderRadius= "400/ 2 " alt="logo" />
          <Link to='/' className='navbar-logo'   onClick={handleClick}>
            
   




           G-Pride<br></br>
           Tracesoft Group<br></br>
           Limited
         
            <i class='fa fa-typo3' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fa fa-times' : 'fa fa-bars'} />
            
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Services
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/products'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About Us
              </Link>
              
            </li>
            <li className='nav-item'>
              <Link
                to='/Application'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Application
              </Link>
              
            </li>
            

            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
          
           Contacts
              </Link>
            </li>
          </ul>
     
          {button && <Button buttonStyle='btn--outline'>Contacts</Button>}
        </div>

      </nav>
      <div className="pogba">
    
      </div>
   
    </>
  );
}

export default Navbar;