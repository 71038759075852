import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Content4 from "../Content4"




function Application(){
  return (
      <>
 
      <Content4/>
      <Footer/>
      </>
  )
}
export default Application;