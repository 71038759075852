import React from 'react'
import { Button } from './Button';
import './HeroSection.css'; 
import '../App.css';




function HeroSection() {
    return (
        <div className='hero-container'>
            <video src='C:\Users\User-PC\react-website-yt-1\src\components\videos\video-1.mp4' autoPlay loop muted />
        
            <h1>We Pride in Your Security</h1>
      <p>What are you waiting for?</p>
      <div className='hero-btns'>
      <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
            REQUEST A QUOTE
            </Button>
           
      </div>
        </div>
    )
}

export default HeroSection
