import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Content1 from "../Content1"




function Services(){
  return (
      <>
  
      <Content1/>
      <Footer/>
      </>
  )
}
export default Services;