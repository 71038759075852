import React from 'react'
import './one2.css';
function one2() {
    return (
        <div className="tracking">
            
            <h1 ><u/>VEHICLE TRACKING WITH FLEET MANAGEMENT</h1>
            <h1>Vehicle Tracking</h1>
            
            <p> We do all forms of motor machines ranging from motorbikes, saloon cars, buses, lorries, trailers, to <br></br>
           construction machines, agricultural machines and office equipment. We use the following avenues for<br></br>tracking:<br></br>
            <ul style={{paddingTop:'20px'}}>
                <li>Mobile phone application</li>
                <li>Online tracking from a laptop or  desktop computer</li>
                <li>SMS tracking</li>
                </ul> </p>
                <p style={{paddingTop:'20px'}}>
                Our state of advanced technology tracking system is enabled to transmit real time data based on <br></br>
                location, direction, speed, mileage and configured alerts such geofences or hotspots. The system<br></br>
                alerts are automated and can be accessed through our customers’ preset email address. With <br></br>
                accurate backup partitions, G-pride Tracking system will generate accurate reports and perform <br></br>
                historical vehicle/motorbike movement replays for personalized consumption. 
                </p>
                <p style={{paddingTop:'10px'}}>
                All our GPS Trackers are fitted with engine immobilizers to remotely stop and resume the engine<br></br> 
                according to our clients’ need. We also pride in our competent recovery team who are standby to<br></br>
                 aid effective recovery process in case of theft or untrustworthy handling of tracked mobile assets.<br></br>

                </p>
                <p style={{paddingTop:'20px'}}>We customize our tracking system according to all our customers’ needs. Entrust us today we will<br></br> deliver.</p>
       <h1 style={{paddingTop:'15px'}}>Fleet Management</h1>
       <p> With our fleet management software, our clients enjoy the following:<br></br>
       <ol style={{paddingTop:'20px'}}>
<li>Manage all your customers deliveries</li>
<li>Easily manage your drivers</li>
<li>Monitor fuel consumption rates and manage refueling</li>
<li>Easily group vehicles according to scope of use and manage them as a unit</li>
<li>No limit on the number of vehicles added to the tracking system</li>
<li>Reduce the cost maintenance and servicing for example insurance and tracking renewals</li>
<li>Delivery notifications</li>
<li>Get periodic reports on trips made by the different vehicles</li>
       </ol>
     <h1 style={{paddingTop:'20px'}}>REAL-TIME TRACKING</h1>
     <p>Our team of engineers have developed our own state of the art software which can be<br></br>
      customized  according to our client’s needs.</p>
      <p style={{paddingTop:'10px'}}>Get the exact vehicle location, routes being used and speed in real time.</p>
<p style={{paddingTop:'10px'}}>With quick time interval of location update of at least 10 seconds each, we guarantee <br></br>
 reliability since all our tracking devices are enabled with a 4G GSM network which<br></br> makes GPS data transmission exceptional on our platform.</p>
 <p style={{paddingTop:'10px'}}>We are committed to provide 24/7 visibility of your vehicles using smart devices, laptop<br></br> 
 and desktop computers.</p>
 <p style={{paddingTop:'10px'}}>Our real time tracking will help in proper planning for all your supplies, deliveries and<br></br> validate expenses.</p>
       
       <h1 style={{paddingTop:'20px'}}>GEOFENCE</h1>
       <p>Our geofence system will notify on vehicle movement in and out of pre-programmed <br></br>
       locality via email messages sent to the client’s email address.</p>
       <p style={{paddingTop:'10px'}}>Monitor deliveries done and exact location and time with instant notification. This <br></br>notification send can be banked on for the next step of action thus management made<br></br> easy.</p>
<p>For car hire service providers, geofence will give peace of mind as renters will stick to <br></br>contractual agreements. </p>
      <p style={{paddingTop:'10px'}}>Geofence will also cub unauthorized driving of the vehicles for example after end of<br></br> duty or parking.</p>
       <h1 style={{paddingTop:'20px'}}>HISTORY AND REPLAY</h1>
       <p>Thanks to our sophisticated software that is able to keep history of vehicle movement<br></br> from the date of first installation which makes it easier to generate customized reports<br></br> and perform replay.</p>
       <p style={{paddingTop:'10px'}}>Get PDF format reports that can be printed or emailed to a preset email address for<br></br> further analysis and interpretation.</p>
      <p style={{paddingTop:'10px'}}>Our back up system maintains data infinitely which can be retrieved for the period in question</p>
      <p style={{paddingTop:'10px'}}>In case of suits, our back up history can be used for validations</p>
       
       <h1 style={{paddingTop:'10px'}}>CUT OFF FUEL AND POWER SYSTEM</h1>
       <p>Our tracking devices are enabled with relay systems/immobilizers that remotely<br></br>
        controls vehicle, motorbike or tuktuk in question.</p>
        <p style={{paddingTop:'10px'}}>We immobilize the engine upon client’s request to aid in quick recovery. The procedure<br></br> is safely done and does not tamper with mechanical function of the motor vehicle. We <br></br>also train our client on how safely to perform this exceptional feature.</p>
<p style={{paddingTop:'10px'}}>Easily manage untrustworthy drivers or car hire clients. We simply promise peace of mind.</p>
<h1 style={{paddingTop:'20px'}}>NOTIFICATION AND ALERTS</h1>
<p>We send direct notifications and alerts on notable events that may have  occurred,<br></br> 
related to the vehicle, motorbike or tuktuk being tracked. Be notified when:
<ul style={{paddingTop:'10px'}}>
    <li>The vehicle’s engine is started or stopped (ignition on and off)</li>
    <li>The driver over speeds when driving</li>
    <li>The driver drives the vehicle recklessly, performing harsh acceleration and braking </li>
    <li>The vehicle is driven into and out of a preset geofence</li>
    <li>The vehicle’s external battery is removed and many more types of notifications The </li>
    


</ul>
The notifications sent include the time of the said event occurring and exact location.
</p>
<h1 style={{paddingTop:'20px'}}>ASSET RECOVERY</h1>
<p>With our technical team well vast in vehicle recovery, we guarantee commitment to give<br></br> 
a helping hand.</p>
<p style={{paddingTop:'10px'}}>We have our own fleet of vehicles with standby skilled drivers to swiftly aid in the<br></br> recovery processes when called upon.</p>
<p style={{paddingTop:'10px'}}>The good standing we have with Kenya National Police Service, make recovery process<br></br> easier in case of theft and our terms of recovery are quite affordable with mutual<br></br> understanding.</p>
      <p style={{paddingTop:'10px'}}>We also work with duly registered auctioneers when necessary to aid financial<br></br> institutions recover co-signed assets with utter most professionalism. </p> 
      </p>
       <h1 style={{paddingTop:'20px'}}>WINDOW TINTING</h1>
       <p>
       With best quality finishes, tinting will give you your personal space and security by <br></br>
       regulating light penetration. We do a clean job.
       </p>
       <h1 style={{paddingTop:'20px'}} > CUT OUT SYSTEM</h1>
       <p>To control the car ignition function, we offer a well fitted cut out system which is configured<br></br>
       to customer’s need. It’s necessary for extra car security.
       </p>
       <h1 style={{paddingTop:'20px'}}>CAR AND HOME ALARM SYSTEM</h1>
       <p>Alarm system controls vehicle entry. G-Pride procures best quality and most sensitive alarm <br></br>
       gadgets which are properly fitted with central lock features. We do both basic alarm <br></br>
       (without cut out system) and auto-watch (with cut out system).
       </p>
       <h1 style={{paddingTop:'20px'}}>RADIO AND RADIO EXPANDERS</h1>
       <p>We guarantee top notch radios and radio expanders with proper fitting. We also change radio <br></br>
           foreign languages to English. </p>
           <h1 style={{paddingTop:'20px'}}>CAR IDENTITY</h1>
           <p>We apply vehicle identity (number plate information) on different parts of the vehicle,
                for example,<br></br> side mirrors, car windows and any other parts according to the client’s request.</p>
      
      <h1 style={{paddingTop:'20px'}}>CCTV CAMERA INSTALLATION</h1>
      <p>We install home and workplace CCTV cameras for improved monitoring and remove any<br></br> worries about property when not around. Our service can be accessed remotely, through<br></br>
       the client’s smart phone, thus our clients have peace of mind. Request quote for all your needs.</p>
      <h1 style={{paddingTop:'20px'}}>SOFTWARE DEVELOPMENT</h1>
      <p>
      We offer software development services for all kinds of software including:
      <ol>
          <li>Website development</li>
          <li>Point of sale and inventory management systems</li>
          <li>Resource management systems</li>
          <li>Enterprise systems</li>
          <li>Existing software upgrade</li>
          <li>Technical support for the developed software</li>
      </ol>
      We work round the clock to ensure the software is user friendly and easy to adapt with existing<br></br> systems and deliver the software in a timely fashion.

      </p>
      <h1 style={{paddingTop:'20px'}}>REVERSE CAMERA INSTALLATION</h1>
     <p>We install reverse cameras for your vehicle to ease you into reversing your car with ease and<br></br> 
     also monitor any activity happening behind you as you reverse.</p>
        </div>
    )
}

export default one2;
